<template>
  <div
    ref="wrapperRef"
    class="h-full flex items-center lg:hidden"
    :class="{ [mobileWrapperOpened]: opened }"
    @keydown.esc="close()"
  >
    <base-lazy-hydrate when="interaction" :interaction="['click', 'focus']">
      <icon-menu
        v-model="opened"
        type="button"
        :aria-expanded="opened"
        aria-controls="menu"
        class="mx-2 cursor-pointer"
        :size="mobileNavIconSize"
        @click="toggle"
      >
        <span class="sr-only">{{ $t.menuIconLabel }}</span>
      </icon-menu>
    </base-lazy-hydrate>
    <transition
      enter-from-class="op-0"
      enter-active-class="transform ease-out"
      leave-active-class="transform ease-in"
      leave-to-class="op-0"
    >
      <div
        v-show="opened"
        id="menu"
        data-test-id="vf-mega-menu-mobile"
        class="fixed-0 z-overlay overflow-y-auto transition"
        :class="mobileBackground"
        :style="{ top: `${(header.height.promoBar + header.height.navigation) / 16}rem` }"
      >
        <loyalty-rewards-accordion v-if="$feature.showLoyaltyRewardsInUtilityNav" />
        <lazy-cms-section
          v-if="megaMenu"
          :section="{ name: 'mobile-menu', items: [{ ...megaMenu, type: 'VfCanvasMegaMenuMobile' }] }"
        />
        <vf-utility-navigation
          v-if="utilityLinks"
          :links="utilityLinks"
          class="f-col items-start gap-4 b-t b-grey-70 p-4"
          @click="close(false)"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import type { MegaMenuContent } from '#types/components/cms/mega-menu'
import type { NavigationLink } from '#types/navigation'
import { HeaderContextKey } from '#commerce/components/vf/header/context'

defineProps<{
  megaMenu?: MegaMenuContent
  utilityLinks?: NavigationLink[]
}>()

const { lock, unlock } = useBodyScroll()
const header = useHeaderStore()
const { $gtm } = useNuxtApp()
const router = useRouter()
const { escape } = useMagicKeys()
const {
  brandClasses: { mobileBackground, mobileWrapperOpened },
  mobileNavIconSize
} = useAppConfig().components.cms.megaMenu

const { menuOpened } = inject(HeaderContextKey)!

const wrapperRef = ref<HTMLDivElement>()
const opened = ref(false)
let isEnableTrack = true

const close = (enableTrack = true) => {
  if (!enableTrack)
    isEnableTrack = false

  opened.value = false
}

const toggle = () => {
  opened.value = !opened.value
}

const { activate, deactivate } = useFocusTrap(wrapperRef, {
  checkCanFocusTrap: () => wait(250),
  clickOutsideDeactivates: true,
  initialFocus: false,
  onDeactivate: close
})

whenever(escape, () => opened.value = false)
watch(opened, async () => {
  await nextTick()
  menuOpened.value = opened.value
  if (opened.value) {
    activate()
    lock()
  }
  else {
    deactivate()
    unlock()
  }

  if (isEnableTrack)
    $gtm.push('topNav.onClickHamburgerMenu', opened.value)
  else
    isEnableTrack = true
})

router.beforeEach(() => {
  if (opened.value) close(false)
})
</script>
